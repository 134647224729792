@import '../../styles/variables';

.text-img-block {
  .column {
    flex-direction: column-reverse;
    .align {
      margin: auto;
    }
    .text-col {
      padding: calc(var(--spacing-unit) * 2) 0;
      h1,
      h2,
      h3 {
        color: var(--primary);
      }
      ul {
        margin-bottom: var(--spacing-unit);
      }
      a:not(.btn) {
        text-decoration: underline;
        &.btn {
          text-decoration: none;
        }
      }
      > div > section > div > p:last-child {
        margin-bottom: 0;
      }
      p:last-child {
        margin-bottom: 0;
      }
    }
    .block-align-center > div {
      text-align: center;
    }
    .block-align-center > div > section > div {
      justify-content: center;
    }
    .block-align-left > div {
      text-align: left;
    }
    .block-align-right > div {
      text-align: right;
    }
    .block-align-right > div > section > div {
      justify-content: flex-end;
    }
  }
  .tib-cta-button-container {
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: space-between;
    .cta-button {
      margin-top: var(--spacing-unit);
    }
    .btn {
      width: 100%;
    }
  }
  @include media-breakpoint-up(md) {
    .column {
      &.tib-row {
        flex-direction: row;
        .text-col {
          padding: 0 calc(var(--spacing-unit) * 2) 0 0;
        }
      }
      &.reverse {
        flex-direction: row-reverse;
        .text-col {
          padding: 0 0 0 calc(var(--spacing-unit) * 2);
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .column {
      &.reverse {
        .text-col {
          padding: 0 0 0 5%;
        }
      }
      &.tib-row {
        .text-col {
          padding: 0 5% 0 0;
        }
      }
    }
  }
}
