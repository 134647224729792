@import '../../../styles/variables';

.ti-block {
  background-color: var(--primary);
  padding: 24px 0;
  height: 100%;
  &.reverse {
    flex-direction: row-reverse;
  }
  .image-container,
  .text-container {
    width: 50%;
  }
  .text-container {
    padding: 40px;
  }
  @include media-breakpoint-down(sm) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    &.reverse {
      flex-direction: column-reverse;
    }
    .image-container,
    .text-container {
      width: 100%;
    }
    .image-container {
      height: 100%;
      img {
        object-fit: cover;
      }
    }
    .text-container {
      padding: calc(var(--spacing-unit) * 2) var(--spacing-unit);
    }
  }
}

.block-dots {
  padding-bottom: 56px;
  > section {
    display: flex;
    gap: 20px;
  }
  .sm-dot {
    padding: 0;
    border: 1px solid var(--tertiary-dark);
    width: 12px;
    height: 12px;
    background-color: var(--tertiary-dark);
    border-color: var(--tertiary-dark);
    border-radius: 100%;
    &.active {
      border: 1px solid var(--tertiary-primary);
      background-color: var(--tertiary-primary);
      border-color: var(--tertiary-primary);
    }
  }
}
