@import '../../styles/variables';

.redirect-search {
  position: relative;
  min-height: 320px;
  max-height: 450px;
  height: 50vh;
  .redirect-search-content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    text-align: center;
    .heading {
      color: var(--white);
      margin-bottom: var(--spacing-unit);
    }
    .input-class {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      position: relative;
      .cta-button {
        margin-top: var(--spacing-unit);
      }
      .home-input {
        padding-left: var(--spacing-unit);
        max-width: 460px;
        width: 100%;
        border-radius: 8px;
        border: solid 1px var(--grayer);
        height: 45px;
        font-size: 16px;
      }
    }
  }
}
