@import '../../styles/variables';

.rewards-table {
  p {
    margin-bottom: 0;
  }
  table {
    margin: 0 auto;
    width: 100%;
    .heading {
      padding: 0 var(--spacing-unit);
      p {
        text-transform: uppercase;
        font-family: var(--font-bold);
      }
    }
    td {
      width: 22%;
      &.vertical-align-top {
        vertical-align: text-top;
      }
      &.markdown-cell {
        padding: var(--spacing-unit) 8px;
        > div {
          padding: 8px;
        }
      }
      &.icon-cell {
        padding: var(--spacing-unit);
      }
      svg {
        height: 50px;
        width: 50px;
      }
      .star-member {
        svg > path {
          fill: var(--member);
        }
      }
      .star-pro {
        svg > path {
          fill: var(--pro);
        }
      }
      .star-champion {
        svg > path {
          fill: var(--champion);
        }
      }
    }
    td:first-child {
      width: 34%;
    }
    @include media-breakpoint-down(sm) {
      .heading {
        padding: 0 8px;
      }
      td {
        &.markdown-cell {
          padding: 0;
          > div {
            padding: 4px;
          }
        }
      }
    }
  }
}
